import { ElasticSearchFacetFieldResponse, ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import {
  ElasticSearch,
  ElasticSearchFilterKeys,
  ElasticSearchStringListFilterProperties,
  ElasticSearchStringListFilters,
} from '@interfaces/models/elasticSearch';
import { facetToFilterRename, filterToFacetRename } from '@maps/catalog/search-product-facets-filters-maps';

const SUBCATEGORY_FILTER_GROUPS = ['categoryLvl0', 'categoryLvl1', 'categoryLvl2', 'model'] as const;
const SUBCATEGORY_FILTER_KEYS = SUBCATEGORY_FILTER_GROUPS.map(facetToFilterRename);

export const generateValidFacetIdMap = (
  fieldResponse: ElasticSearchFacetFieldResponse,
): Record<(typeof SUBCATEGORY_FILTER_GROUPS)[number], string[]> =>
  SUBCATEGORY_FILTER_GROUPS.reduce(
    (acc, filterGroup) => {
      acc[filterGroup] = fieldResponse[filterGroup]?.map((facet) => facet.id) || [];
      return acc;
    },
    {} as Record<(typeof SUBCATEGORY_FILTER_GROUPS)[number], string[]>,
  );

const isSubcategoryFilter = (filterKey: ElasticSearchFilterKeys) => SUBCATEGORY_FILTER_KEYS.includes(filterKey);

const filtersHasSubcategories = (filters: ElasticSearch['filters']) => Object.keys(filters).some(isSubcategoryFilter);

const getSubcategoryFilters = (filters: ElasticSearch['filters']): ElasticSearchStringListFilters =>
  Object.keys(filters).reduce((acc, filterKey: ElasticSearchFilterKeys) => {
    if (isSubcategoryFilter(filterKey)) {
      acc[filterKey] = filters[filterKey];
    }
    return acc;
  }, {} as ElasticSearchStringListFilters);

const removeInvalidSubcategoryFilters = ({
  filters,
  queryResult,
}: {
  filters: ElasticSearch['filters'];
  queryResult: ElasticSearchResponse;
}): { filters: ElasticSearch['filters']; hasChanged: boolean } => {
  let hasChanged = false;
  if (!filters || !filtersHasSubcategories(filters) || !queryResult?.facets?.fields) {
    return { filters, hasChanged };
  }
  const subCategoryFilters = getSubcategoryFilters(filters);
  const facetIdMap = generateValidFacetIdMap(queryResult.facets.fields);
  const sanitizedSubcategoryFilters = Object.keys(subCategoryFilters).reduce(
    (acc, filterKey: ElasticSearchStringListFilterProperties) => {
      const acceptableFacetIds = facetIdMap[filterToFacetRename(filterKey)];
      if (Array.isArray(acceptableFacetIds)) {
        acc[filterKey] = subCategoryFilters[filterKey].filter((filterId) => acceptableFacetIds.includes(filterId));
        if (acc[filterKey].length !== subCategoryFilters[filterKey].length) {
          hasChanged = true;
        }
      }
      return acc;
    },
    {},
  );
  return {
    filters: { ...filters, ...sanitizedSubcategoryFilters },
    hasChanged,
  };
};

export default removeInvalidSubcategoryFilters;
