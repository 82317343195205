import { useSessionStorage } from 'usehooks-ts';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import { useCallback, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

interface SearchQueryId {
  searchPayloadHash: string;
  uuid: string;
}

const sortObjectKeysAlphabetically = (obj: object) => {
  const keysArray = Object.keys(obj);

  keysArray.sort();

  const sortedObject = {};
  for (const key of keysArray) {
    sortedObject[key] = obj[key];
  }

  return sortedObject;
};

export interface SearchQueryIdState {
  assignSearchQueryId: (searchPayload: Partial<ElasticSearch>) => string;
  id: string;
  hasLoaded: boolean;
}

const useSearchQueryId = (): SearchQueryIdState => {
  const [searchQueryId, setSearchQueryId] = useSessionStorage<SearchQueryId | null>('sessionsave/searchQueryId', null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const assignSearchQueryId = useCallback(
    (searchPayload: Partial<ElasticSearch>) => {
      const { filters, sortBy, q, mySizes, locale } = searchPayload;
      const sortedFilters = filters ? sortObjectKeysAlphabetically(filters) : '';
      const sortedLocale = locale ? sortObjectKeysAlphabetically(locale) : '';
      const sortedSizes = mySizes?.sizes ? sortObjectKeysAlphabetically(mySizes.sizes) : '';
      const modifiedPayload = {
        filters: sortedFilters,
        locale: sortedLocale,
        sortBy: sortBy ?? '',
        q: q ?? '',
        mySizesEnabled: mySizes?.isEnabled ?? '',
        mySizesSizes: sortedSizes ?? '',
        mySizesUniverse: mySizes?.['universe.id'] ?? '',
      };
      const searchPayloadHash = JSON.stringify(modifiedPayload);
      if (!searchQueryId || searchPayloadHash !== searchQueryId.searchPayloadHash) {
        const newSearchQueryId = {
          searchPayloadHash,
          uuid: uuidV4(),
        };
        setSearchQueryId(newSearchQueryId);
        setHasLoaded(true);
        return newSearchQueryId.uuid;
      }
      setHasLoaded(true);
      return searchQueryId.uuid;
    },
    [searchQueryId, setSearchQueryId],
  );

  return { assignSearchQueryId, id: searchQueryId?.uuid ?? '', hasLoaded };
};

export default useSearchQueryId;
