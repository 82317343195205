import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { ElasticSearch } from '@interfaces/models/elasticSearch';

export const DEFAULT_RESULTS_PER_PAGE = 48;

const PERMITTED_RESULTS_PER_PAGE = [DEFAULT_RESULTS_PER_PAGE * 2, DEFAULT_RESULTS_PER_PAGE];

const getDefaultResultsPerPage = (resultsPerPageQuery: string | string[]) => {
  if (!resultsPerPageQuery || Array.isArray(resultsPerPageQuery)) {
    return DEFAULT_RESULTS_PER_PAGE;
  }
  const resultsPerPage = parseInt(resultsPerPageQuery);
  return PERMITTED_RESULTS_PER_PAGE.includes(resultsPerPage) ? resultsPerPage : DEFAULT_RESULTS_PER_PAGE;
};

const useResultsPerPage = ({ curUrlRef }: { curUrlRef: React.MutableRefObject<string> }) => {
  const { query } = useRouter();
  const [resultsPerPage, setResultsPerPageState] = useState<ElasticSearch['pagination']['limit']>(
    getDefaultResultsPerPage(query?.pageSize),
  );
  const setResultsPerPage = useCallback((input: number) => {
    setResultsPerPageState(input);
    const { pathname, search, hash, origin } = window.location;
    const queryParams = new URLSearchParams(search);
    if (input === DEFAULT_RESULTS_PER_PAGE) {
      queryParams.delete('pageSize');
    } else {
      queryParams.set('pageSize', input.toString());
    }

    const queryString = queryParams.toString();
    const newUrl = `${origin}${pathname}${queryString ? `?${queryParams}` : ''}${hash}`;
    curUrlRef.current = newUrl;
    window.history.replaceState(null, undefined, newUrl);
  }, []);

  return { resultsPerPage, setResultsPerPage };
};

export default useResultsPerPage;
