import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Pagination from '@components/catalog/pagination/pagination';
import clsx from 'clsx';
import useWindowSize from '@hooks/use-window-size';
import styles from './generic-pagination.module.scss';

interface GenericPaginationProps {
  paginationMeta: PaginationState;
  defaultLimit: number;
  handlePaginationChange: (page: number, itemsPerPage: number) => void;
  handleItemsPerPageClick: (count: number) => void;
  dataCyPagination?: {
    container?: string;
    resultsLabel?: string;
    resultsItem?: string;
  };
  itemsPerPage?: number;
  pageType?: string;
}

interface PaginationState {
  currentPage: number;
  totalPages: number;
  totalCount?: number;
}

const GenericPagination: React.FC<GenericPaginationProps> = (props): React.JSX.Element => {
  const {
    paginationMeta,
    defaultLimit,
    handlePaginationChange,
    handleItemsPerPageClick,
    dataCyPagination = {
      container: 'generic-container',
      resultsLabel: 'generic-results-label',
      resultsItem: 'generic-results-item',
    },
    itemsPerPage,
    pageType,
  } = props;
  const limitValues = [defaultLimit, defaultLimit * 2];
  const { t } = useTranslation();
  const { isWiderThanLg } = useWindowSize();

  const handleClick = (v: number) => {
    handleItemsPerPageClick(v);
  };

  const totalPageSize = useMemo(() => {
    if (pageType === 'profile-items-for-sale') {
      return paginationMeta?.totalPages;
    }
    let pageSize = Math.ceil(paginationMeta.totalCount / itemsPerPage);

    if (pageSize && pageSize < paginationMeta?.currentPage) {
      pageSize = paginationMeta?.currentPage;
    }

    return pageSize;
  }, [paginationMeta?.currentPage, itemsPerPage, paginationMeta?.totalPages]);

  return (
    <>
      <div
        className={styles.genericPagination}
        data-cy={dataCyPagination?.container}
      >
        {isWiderThanLg && (
          <div data-cy={dataCyPagination?.resultsLabel}>
            {t('CATALOG.FILTERS.HITS_PER_PAGE_LABEL')}
            {limitValues.map((v) => (
              <button
                key={v}
                className={clsx(styles.genericPagination__itemsPerPage, {
                  [styles['genericPagination__itemsPerPage--bold']]: itemsPerPage === v,
                })}
                onClick={() => handleClick(v)}
                data-cy={`${dataCyPagination?.resultsItem}-${v}`}
              >
                {v}
              </button>
            ))}
          </div>
        )}
        <Pagination
          currentPage={paginationMeta?.currentPage}
          pageCount={totalPageSize}
          showText={false}
          hasArrows={true}
          buttonsToShow={5}
          customActiveStyle={false}
          onChange={(page) => handlePaginationChange(page, itemsPerPage)}
        />
      </div>
    </>
  );
};

export default GenericPagination;
