import { ElasticSearchFacet } from '@interfaces/api/responses/elasticSearch';

type GroupedModel = {
  [key: string]: ElasticSearchFacet[];
};

const groupModels = (models: ElasticSearchFacet[]): GroupedModel => {
  return models.reduce((acc: ElasticSearchFacet, el: ElasticSearchFacet) => {
    acc[el.name] = [...(acc[el.name] || []), el];

    return acc;
  }, Object.create(null));
};

const formatModels = (models: GroupedModel): ElasticSearchFacet[] => {
  return Object.keys(models).map((name: string) => {
    let count = 0;

    // Sum count of all models with the same name
    models[name].forEach((model: ElasticSearchFacet) => {
      count = count += model.count;
    });

    // Set the ID of the first element in the array of models with the same name
    return { name, count, id: models[name][0].id };
  });
};

const mapModels = (models: ElasticSearchFacet[]): ElasticSearchFacet[] => {
  const groupedModels = groupModels(models);

  return formatModels(groupedModels);
};

export default mapModels;
