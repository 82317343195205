import React from 'react';
import clsx from 'clsx';
import styles from './product-feed-layout.module.scss';

type ProductFeedLayoutProps = {
  header?: React.ReactNode;
  productList?: React.ReactNode;
  customClass?: string;
  hotFilters?: React.ReactNode;
  pagination?: React.ReactNode;
  shouldShowCmsProductsOnEmpty?: boolean;
};

const ProductFeedLayout: React.FC<ProductFeedLayoutProps> = ({
  header,
  productList,
  customClass,
  hotFilters,
  pagination,
  shouldShowCmsProductsOnEmpty,
}) => {
  return (
    <main className={clsx(styles.productFeedLayout, customClass)}>
      {header && <div className={clsx(styles.productFeedLayout__container, 'innerContainer')}>{header}</div>}
      <div
        className={clsx(styles.productFeedLayout__wrapper, {
          [styles['productFeedLayout__wrapper--cmsProductsBlock']]: !!shouldShowCmsProductsOnEmpty,
        })}
      >
        {hotFilters && <div className={clsx(styles.productFeedLayout__hotfilters, 'innerContainer')}>{hotFilters}</div>}
        {productList && (
          <div
            className={clsx(styles.productFeedLayout__products, 'innerContainer', {
              [styles['productFeedLayout__products--cmsProductsBlock']]: !!shouldShowCmsProductsOnEmpty,
            })}
          >
            {productList}
          </div>
        )}
        {pagination && <div className={clsx(styles.productFeedLayout__pagination, 'innerContainer')}>{pagination}</div>}
      </div>
    </main>
  );
};

export default ProductFeedLayout;
