import { useQuery } from '@tanstack/react-query';
import { MarketingFiltersQueryKeys } from '@enums/react-query-keys';
import { ContentsMarketingFilter } from '@interfaces/models/contentsMarketingFilter';
import { getMarketingFilters } from '@api/contents';
import { useMemo } from 'react';

const useMarketingFiltersQuery = () => {
  const { data: marketingFilters } = useQuery<ContentsMarketingFilter[]>({
    queryKey: [MarketingFiltersQueryKeys.MARKETING_FILTERS],
    staleTime: Infinity,
    queryFn: () => getMarketingFilters(),
  });

  const localToLocalFilter = useMemo(
    () => marketingFilters?.find((filter) => filter.filterType === 'sustainability'),
    [marketingFilters],
  );
  const freeShippingFilter = useMemo(
    () => marketingFilters?.find((filter) => filter.filterType === 'freeShipping'),
    [marketingFilters],
  );

  return {
    marketingFilters: marketingFilters || [],
    localToLocalFilter,
    freeShippingFilter,
  };
};

export default useMarketingFiltersQuery;
