import React from 'react';
import extractFromContext from '@helpers/utils/extract-from-context';
import { GetServerSideProps, NextPageWithLayout } from 'next';
import DefaultLayout from '@layouts/default-layout';
import { dehydrate, DehydratedState, QueryClient, useHydrate } from '@tanstack/react-query';
import ProfileTopSection from '@components/organisms/profile/profile-top-section/profile-top-section';
import ProfileTabs from '@components/organisms/profile/profile-tabs/profile-tabs';
import { DEFAULT_PAGINATION_LIMIT, getUserProfile } from '@api/profile';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ProfileQueryKeys } from '@enums/react-query-keys';
import { isRequest404Error, isRequestError } from '@helpers/errors';
import logger from '@helpers/utils/logger/client';
import SearchService from '@services/search-service';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import { UserProfile } from '@interfaces/models/userProfile';
import { ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { SSRConfig } from 'next-i18next';
import Head from '@components/atoms/head/head';
import { ContentsPageMetadata } from '@interfaces/models/contentsPageMetadata';
import { getAppInitialProps } from '@helpers/initial-props';

type ProfilePageProps = {
  dehydratedState: DehydratedState;
};

const profileMetaData = {
  robots: 'noindex, follow',
} as ContentsPageMetadata;

const ProfilePage: NextPageWithLayout<ProfilePageProps> = (props): React.JSX.Element => {
  const { dehydratedState } = props;
  useHydrate(dehydratedState);
  return (
    <>
      <Head metadata={profileMetaData} />
      <div className="innerContainer">
        <ProfileTopSection />
      </div>
      <ProfileTabs />
    </>
  );
};

ProfilePage.Layout = [DefaultLayout];
ProfilePage.PageType = 'ProfilePage';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { preferences, locale, serverAxiosRequestData } = extractFromContext(context);
  const { profileSlug: profileId, sortBy } = context.query as { profileSlug: string; sortBy: ElasticSearch['sortBy'] };

  if (!profileId || profileId?.length < 1) {
    return {
      redirect: { destination: '/', permanent: false },
    };
  }

  try {
    const queryClient = new QueryClient();
    const initialProps = await getAppInitialProps(context, queryClient);
    // @ts-ignore
    const searchPayload: Partial<ElasticSearch> = {
      sortBy,
      pagination: { offset: 0, limit: DEFAULT_PAGINATION_LIMIT },
      locale: {
        country: preferences?.country,
        language: preferences?.language,
        currency: preferences?.currency,
        sizeType: preferences?.country,
      },
      filters: {
        'seller.id': [profileId[0]],
        ...{ sold: ['0'] },
      },
      mySizes: null,
    };

    const apiRequests = [
      serverSideTranslations(locale, ['default-layout', 'common', 'profile']),
      ...[
        queryClient.fetchQuery({
          queryKey: [ProfileQueryKeys.PROFILE_USER],
          queryFn: () => getUserProfile(profileId[0], serverAxiosRequestData),
        }),
      ],
      ...(profileId[1] === 'items-for-sale'
        ? [
            queryClient.prefetchQuery({
              queryKey: [
                ProfileQueryKeys.PROFILE_MY_ITEMS_FOR_SALE,
                profileId[0],
                sortBy,
                [],
                searchPayload.pagination.limit,
                searchPayload.pagination.offset,
                0,
              ],
              queryFn: () => SearchService.productSearch(searchPayload),
            }),
          ]
        : [null]),
    ];

    const promiseResults = await Promise.allSettled([...apiRequests]);

    const [translations, , ,] = promiseResults.map((promiseResult) => {
      if (promiseResult.status === 'fulfilled') {
        return promiseResult.value;
      }
      return null;
    }) as [SSRConfig, UserProfile | null, ElasticSearchResponse];

    return {
      props: {
        ...initialProps,
        ...translations,
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      },
    };
  } catch (error) {
    // Axios 404 errors should get redirected straight to error page
    if (isRequestError(error)) {
      if (!isRequest404Error(error)) {
        logger.error(
          error,
          'There was an api request error on the Profile page - getServerSideProps - [...profileSlug].page.tsx',
        );
      }
      return {
        notFound: true,
      };
    }
    logger.error(
      error,
      'The server failed to rendre the Profile page - getServerSideProps - [...profileSlug].page.tsx',
    );
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }
};

export default ProfilePage;
