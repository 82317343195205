import React from 'react';
import { ButtonProps } from '@components/atoms/button/button';
import Environment from '@config/index';
import clsx from 'clsx';
import styles from './product-feed-empty-state.module.scss';

export type ImageEmptyState = {
  path: string;
  width?: number;
  height?: number;
};

export type EmptyState = {
  titleText: string;
  subTitleText: string;
  ctaText?: string;
  buttonVariant?: ButtonProps['variant'];
  clickHandler?: () => void;
  ctaClassName?: string;
  pageType?: string;
  image?: ImageEmptyState;
  hasCMSProductsBlock?: boolean;
};

type ProductFeedEmptyStateProps = {
  emptyState: EmptyState;
};

const ProductFeedEmptyState: React.FC<ProductFeedEmptyStateProps> = (props) => {
  const {
    titleText,
    subTitleText,
    clickHandler,
    ctaText,
    ctaClassName,
    buttonVariant = 'rounded-secondary',
    pageType = 'personalized',
    image = { path: '/images/search/no-results.svg' },
    hasCMSProductsBlock,
  } = props.emptyState;

  return (
    <div
      className={clsx(styles.productFeedEmpty, {
        [styles['productFeedEmpty--cmsProductsBlock']]: hasCMSProductsBlock,
      })}
    >
      <div
        className={styles.productFeedEmpty__img}
        data-cy={`${pageType}__empty__image_icon`}
      >
        <img
          loading="lazy"
          width={image?.width ?? 80}
          height={image?.height ?? 80}
          src={`${Environment.assetsPath}${image.path}`}
          alt=""
        />
      </div>
      <div className={styles.productFeedEmpty__texts}>
        <p
          className={styles.productFeedEmpty__texts__title}
          data-cy={`${pageType}__empty__title`}
        >
          {titleText}
        </p>
        <p
          className={styles.productFeedEmpty__texts__subtitle}
          data-cy={`${pageType}__empty__text`}
        >
          {subTitleText}
        </p>
      </div>

      {ctaText && (
        <div
          className={styles.productFeedEmpty__cta}
          data-cy={`${pageType}__empty__cta`}
        >
          <button
            type="button"
            role="link"
            className={clsx(`vc-btn vc-btn--${buttonVariant} vc-btn--medium`, ctaClassName)}
            onClick={clickHandler}
            data-cy={`${pageType}__empty__cta__button`}
          >
            {ctaText}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductFeedEmptyState;
