import { useEffect, useState } from 'react';
import { ElasticSearchFacetsFields, InnerFeedContext } from '@interfaces/models/elasticSearch';
import { isDirectShippingPage, isExpressDeliveryPage } from '@helpers/routing';

export type StaticFilterGroups = Partial<{ [key in ElasticSearchFacetsFields]: boolean }>;

const INITIAL_STATIC_FILTERS = {
  universe: false,
  categoryLvl0: false,
  categoryLvl1: false,
  categoryLvl2: false,
  brand: false,
  stock: false,
  model: false,
  directShippingEligible: false,
  dealEligible: false,
};

const useStaticFilterGroups = () => {
  const [staticFilterGroups, setStaticFilterGroups] = useState<StaticFilterGroups>(INITIAL_STATIC_FILTERS);

  useEffect(() => {
    const path = window.location.pathname;
    const query = window.location.search;
    const queryParams = new URLSearchParams(query);
    if (isExpressDeliveryPage(path)) {
      setStaticFilterGroups((prevState) => ({
        ...prevState,
        stock: true,
      }));
    }
    if (isDirectShippingPage(path)) {
      setStaticFilterGroups((prevState) => ({
        ...prevState,
        directShippingEligible: true,
      }));
    }
    // On deal-hunting landing page we want to disable the dealEligible filter
    if (queryParams?.get('innerFeedContext') === InnerFeedContext.DealHunting) {
      setStaticFilterGroups((prevState) => ({
        ...prevState,
        dealEligible: true,
      }));
    }
  }, []);

  return { staticFilterGroups, setStaticFilterGroups };
};

export default useStaticFilterGroups;
