import { Product } from '@interfaces/models/product';
import React, { FCWithChildren, useEffect } from 'react';
import clsx from 'clsx';
import ProductCard, { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card';
import isUSPBlockPositionMatched from '@helpers/utils/usp-block-matcher';
import Usp from '@components/organisms/usp/usp';
import { PageType } from '@interfaces/common/common';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import useLazyLoadSimilarProducts from '@hooks/product-feed/use-lazy-load-similar-products';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import useRestorePageScrollPosition from '@hooks/use-restore-page-scroll-position';
import AuthGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import { useAuthentication } from '@context/authentication.context';
import { useBucket } from '@context/bucket.context';
import styles from './products-list.module.scss';

type USPBlockInfo = {
  showUspBlock: boolean;
  uspBlockProductCount: number;
};

export type ProductListProps = {
  products: Product[] | CatalogProduct[];
  pageType: PageType;
  uspBlockInfo?: USPBlockInfo;
  ProductCardWrapper?: FCWithChildren<{ index: number }>;
  children?: React.ReactNode;
  productCardProps?: Partial<ProductCardProps>;
  shouldLazyLoadProducts?: boolean;
  includeSchemaInProductCard?: boolean;
};

const ProductsList: React.FC<ProductListProps> = (props) => {
  const {
    products,
    pageType = 'personalized',
    uspBlockInfo = null,
    ProductCardWrapper = null,
    productCardProps = {},
    shouldLazyLoadProducts = false,
    includeSchemaInProductCard = true,
  } = props;

  const { sliceIndex, onThresholdCrossed, renderedProducts, ProductLazyLoadWrapper } = useLazyLoadSimilarProducts(
    products,
    pageType,
    shouldLazyLoadProducts,
  );
  const { shouldDisplayAuthDialog, setShouldDisplayAuthDialog, setRedirectUrl } = useAuthentication();
  const { scrollPosition, scrollToElementByDataCy } = useRestorePageScrollPosition({});
  const { isFeatureEnabled } = useBucket();
  const isGuestXpPdp = isFeatureEnabled((f) => f.guestXpPdp, true);

  useEffect(() => {
    if (!!scrollPosition) {
      scrollToElementByDataCy(scrollPosition);
    }
  }, []);

  return (
    <ul
      className={styles.productsList__flexContainer}
      data-cy={`${pageType}__productList__flexContainer`}
    >
      {renderedProducts?.map((product: Product, productIndex: number) => (
        <React.Fragment key={product.id}>
          <li
            key={`${product.id}_${productIndex}`}
            className={styles['productsList__flexContainer--item']}
            data-cy={`${pageType}__productList__flexContainer__item`}
          >
            <ConditionalWrapper
              condition={shouldLazyLoadProducts}
              wrap={(children) => (
                <ProductLazyLoadWrapper
                  index={productIndex}
                  sliceIndex={sliceIndex}
                  onThresholdCrossed={onThresholdCrossed}
                  shouldLazyLoadProducts={shouldLazyLoadProducts}
                >
                  {children}
                </ProductLazyLoadWrapper>
              )}
            >
              <ConditionalWrapper
                condition={ProductCardWrapper !== null}
                wrap={(children) => <ProductCardWrapper index={productIndex}>{children}</ProductCardWrapper>}
              >
                <ConditionalWrapper
                  condition={!isGuestXpPdp && shouldDisplayAuthDialog}
                  wrap={(children) => (
                    <AuthGuard
                      onClick={() => {
                        setShouldDisplayAuthDialog(false);
                        setRedirectUrl({ url: product.path, mode: 'push' });
                      }}
                      shouldPreventDefault={true}
                      onAuth={() => (window.location.pathname = product.path)}
                    >
                      {children}
                    </AuthGuard>
                  )}
                >
                  <ProductCard
                    customClass={styles.productsList__flexContainer__card}
                    data-cy={`${pageType}__productList__flexContainer__card`}
                    product={product}
                    index={productIndex}
                    showProductTags
                    showSellerBadge
                    showProductSize
                    showProductExpressDelivery
                    showProductLocation={true}
                    showProductDirectShipping
                    showDiscountPrice
                    isFavourite
                    disableLazyLoad={productIndex <= 7}
                    fetchpriority={productIndex <= 7 ? 'high' : 'low'}
                    pageType={pageType}
                    containerId={`product_id_${product.id}`}
                    includeProductSchema={includeSchemaInProductCard}
                    {...productCardProps}
                  />
                </ConditionalWrapper>
              </ConditionalWrapper>
            </ConditionalWrapper>
          </li>
          {uspBlockInfo?.showUspBlock &&
            isUSPBlockPositionMatched(productIndex, products.length, uspBlockInfo?.uspBlockProductCount) && (
              <li
                className={clsx(
                  styles['productsList__flexContainer--item'],
                  styles['productsList__flexContainer--item--uspContainer'],
                )}
              >
                <Usp pageType="hero_pdp" />
              </li>
            )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default React.memo(ProductsList);
