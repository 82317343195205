import Environment from '@config/index';
import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import { ContentsMarketingFilter } from '@interfaces/models/contentsMarketingFilter';
import { ApiResponse } from '@interfaces/api';
import { TouchPoint } from '@interfaces/common/common';
import { WelcomeVoucher } from '@interfaces/models/welcomeVoucher';

const BASE_URL = `${Environment.apiBaseUrl}/contents`;

export const getMarketingFilters = async (
  serverAxiosRequestData?: ServerAxiosRequestData,
): Promise<ContentsMarketingFilter[]> => {
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);
  if (serverAxiosRequestData) {
    const { language } = serverAxiosRequestData.preferences;
    axiosConfig.params.lang = language;
  }

  const {
    data: { data },
  } = await axios.get<ApiResponse<ContentsMarketingFilter[]>>(`${BASE_URL}/filters/marketing`, axiosConfig);
  return data;
};

export const getAnonymousUserWelcomeVoucher = async (
  touchPoint: TouchPoint,
  ldAnonymousId: string,
  serverAxiosRequestData?: ServerAxiosRequestData,
  productId?: string,
): Promise<WelcomeVoucher> => {
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);
  axiosConfig.params = {
    ...axiosConfig.params,
    touchpoint: touchPoint,
  };

  if (productId) {
    axiosConfig.params.productId = productId;
  }

  if (ldAnonymousId) {
    axiosConfig.headers = {
      ...axiosConfig.headers,
      'x-ld-anonymous-id': ldAnonymousId,
    };
  }

  const {
    data: { data },
  } = await axios.get<ApiResponse<WelcomeVoucher>>(`${BASE_URL}/welcome-voucher`, axiosConfig);
  return data;
};
